import { useState, useEffect, useRef } from 'react';

import {
    useDisclosure,
    Box,
    AccordionButton, 
    AccordionIcon,
    Input,
    Menu,
    MenuList,
    MenuItem,
} from '@chakra-ui/react';

import Const from '../Constants';

const AccordionButtonInput = ({ auth, type, group, data, setData, onClick }) => {
  const [groupTitle, setGroupTitle] = useState(group);
  const [groupNewTitle, setGroupNewTitle] = useState(group);

  const [isEditing, setIsEditing] = useState(false);
  // Right click menu
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

  const groupInputRef = useRef(null);

  useEffect(() => {
    setGroupTitle(group);
    setGroupNewTitle(group);
  }, [group]);

  const handleUpdateGroup = async (groupTitle, groupNewTitle) => {
    try {
      const idToken = await auth.currentUser.getIdToken();
      const theURL = process.env.REACT_APP_BASE_URL + `/${type}/group`;
      const response = await fetch(theURL, {
        method: Const.HttpPatch,
        headers: new Headers({
          Authorization: idToken,
          Accept: Const.AppJson,
          [Const.ContentType]: Const.AppJson
        }),
        body: JSON.stringify({
          groupTitle: groupTitle,
          groupNewTitle: groupNewTitle
        })
      });

      // Check if the request was successful
      if(!response.ok) {
        throw new Error(`Failed to update ${type} group`);
      }
    } catch (error) {
      console.error(error.message);
    }
  }

  const handleBlur = async (event) => {
    if(groupTitle !== groupNewTitle) { // TODO handle the empty case as well
      // Update the group title on backend
      handleUpdateGroup(groupTitle, groupNewTitle);

      // Update the data
      const copyData = JSON.parse(JSON.stringify(data));

      copyData.forEach(item => { // TODO handle other case, which cases?
        if(item.group === groupTitle) {
            item.group = groupNewTitle;
        }
      });

      setData(copyData);

      // Update the orig title as well for the next time.
      setGroupTitle(groupNewTitle);
    }
  };

  const handleRightClick = (event) => {
    event.preventDefault(); // Prevent the default right-click menu
    setMenuPosition({ x: event.clientX, y: event.clientY });
    onOpen();
  };

  return (
  <Box>
    <AccordionButton
      height={'30px'}
      paddingLeft={0}
      _hover={{ background: Const.Gray69 }}
    >

      <Input
          isReadOnly={!isEditing}
          height={'30px'}
          flex={'1'}
          ref={groupInputRef} // Set the reference to the input
          fontSize={Const.SM}
          fontWeight={Const.Bold}
          textAlign={Const.Left}
          color={Const.Gray180}
          value={groupNewTitle}
          border={Const.sNone}
          _hover={{ background: 'none' }}
          style={{ cursor: Const.sDefault }}
          onContextMenu={handleRightClick}
          onClick={(event) => {
            onClick();
            event.preventDefault();
          }}
          onChange={(event) => {
            setGroupNewTitle(event.target.value);
          }}
          onBlur={(event) => {
            setIsEditing(false);
            handleBlur(event);
          }}
          onKeyDown={(event) => {
            if(event.key === Const.sEnter) {
              // Remove focus
              groupInputRef.current.blur();
              event.preventDefault(); // Prevents any default action
            } else if (event.key === ' ') {
              // we cancel space event so section is not expanded collapsed.
              // So we need to handle it manually here.

              // Insert space at the cursor position
              const cursorPosition = event.target.selectionStart;
              const newValue = groupNewTitle.slice(0, cursorPosition) + ' ' + groupNewTitle.slice(cursorPosition);
              setGroupNewTitle(newValue);

              // Set cursor position after the space
              setTimeout(() => {
                groupInputRef.current.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
              }, 0);

              // Prevent space from expanding/collapsing the accordion
              event.preventDefault();
            }
          }}
      />

      <AccordionIcon onClick={onClick} />
    </AccordionButton>

    <Menu isOpen={isOpen} onClose={onClose}>
        <MenuList
          background={Const.Gray46}
          borderColor={Const.Gray64}
          style={{
            position: 'absolute',
            top: `${menuPosition.y}px`,
            left: `${menuPosition.x}px`
          }}
        >

        <MenuItem
            color={Const.White}
            background={Const.Gray46}
            fontSize={Const.SM}
            onClick={() => {

              setIsEditing(true);
              setTimeout(() => {
                if (groupInputRef.current) {
                  groupInputRef.current.focus();
                  groupInputRef.current.setSelectionRange(groupNewTitle.length, groupNewTitle.length); // Move cursor to end
                }
              }, 10); // Somehow works better than 0
            }}
            _hover={{ background: Const.Gray64 }}
        >
            Edit group name
        </MenuItem>

        </MenuList>
      </Menu>

    </Box>
  );
};

export default AccordionButtonInput;
