import { useState, useEffect, useRef } from 'react';

import {
    Box,
    Button,
    VStack,
    Accordion, 
    AccordionItem, 
    AccordionButton, 
    AccordionPanel, 
    AccordionIcon
} from '@chakra-ui/react';

// import { useState, useEffect } from 'react';
import { AddIcon } from '@chakra-ui/icons';

import SideBarButton from './SideBarButton';

import Const from './Constants';
import { isEqual, handleUpdateUserConfig } from './Utils';

const InterviewsBar = ({
  auth,
  userConfig,
  setUserConfig,
  interviewsData,
  currentInterview,
  setCurrentInterview,
  handleAddInterview
}) => {
  const isFirstRender = useRef(true); // Track if it's the first render

  const [expandedGroups, setExpandedGroups] = useState(userConfig.local[Const.sInterviews.toLowerCase()].expandedGroups);

  useEffect(() => {

    // Set current task based on the currentId in the userConfig.
    Object.keys(interviewsData).forEach((key) => {

      const cur = interviewsData[key].find(item => item.id === userConfig.local[Const.sInterviews.toLowerCase()].currentId);
      if(cur) {
        setCurrentInterview({
          type: cur.type,
          data: cur
        });
        return;
      }
    });
  // eslint-disable-next-line
  }, [interviewsData]);


  useEffect(() => {
    if (isFirstRender.current) {
      // Skip the first call
      isFirstRender.current = false;
      return;
    }
    
    handleViewConfigUpdate();
  // eslint-disable-next-line
  }, [expandedGroups]);

  useEffect(() => {
    if(currentInterview) {
      handleViewConfigUpdate();
    }
  // eslint-disable-next-line
  }, [currentInterview]);

  const handleAccordionButtonClick = (index) => {
    setExpandedGroups((prevExpandedGroups) => {
      if (prevExpandedGroups.includes(index)) {
        return prevExpandedGroups.filter((i) => i !== index);
      } else {
        return [...prevExpandedGroups, index];
      }
    });
  };

  function handleViewConfigUpdate() {
    if(userConfig && (
      userConfig.local[Const.sInterviews.toLowerCase()].expandedGroups !== expandedGroups ||
      userConfig.local[Const.sInterviews.toLowerCase()].currentId !== (currentInterview ? currentInterview.data.id : null))) {
      handleUpdateUserConfig(auth, userConfig, setUserConfig, {
        [Const.sInterviews.toLowerCase()]: {
          currentId: currentInterview ? currentInterview.data.id : null,
          expandedGroups
        }
      });
    }
  }

  async function handleButtonAction(type, interview) {
    // Change only if there is a state change
    const newCurrentInterview = {
      type: type,
      data: interview
    };
    if(!isEqual(currentInterview, newCurrentInterview)) {
      setCurrentInterview(newCurrentInterview);
    }
  }

  return (
    <VStack
        w={'260px'}
        minW={'260px'}
        maxW={'260px'}
        height={'100vh'}
        background={Const.Gray46}
        padding={'10px'}
        spacing={'4px'}
        overflowY={'auto'}>
        <Accordion 
          index={expandedGroups}
          allowMultiple
          width={'100%'}
        >
          {/* Global Templates Section */}
          <AccordionItem border={Const.sNone}>
            <h2>
              <AccordionButton
                _hover={{ background: Const.Gray69 }}
                onClick={() => handleAccordionButtonClick(0)}
              >
                <Box flex={'1'} textAlign={Const.Left} fontSize={Const.SM} fontWeight={Const.Bold} color={Const.Gray180}>
                  { 'Custom Templates' }
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {interviewsData.global.map((template) => (
                <SideBarButton
                    key={template.id}
                    current={currentInterview && currentInterview.data.id === template.id}
                    title={template.title}
                    action={()=> {
                      handleButtonAction(Const.sGlobal, template);
                    }}
                />
              ))}
            </AccordionPanel>
          </AccordionItem>

          {/* Local Templates Section */}
          <AccordionItem border={Const.sNone}>
            <h2>
              <AccordionButton
                _hover={{ background: Const.Gray69 }}
                onClick={() => handleAccordionButtonClick(1)}
              >
                <Box flex={'1'} textAlign={Const.Left} fontSize={Const.SM} fontWeight={Const.Bold} color={Const.Gray180}>
                  {'My Templates'}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {interviewsData.local.map((template) => (
                <SideBarButton
                    key={template.id}
                    current={currentInterview && currentInterview.data.id === template.id}
                    title={template.title}
                    action={()=> {
                      handleButtonAction(Const.sLocal, template);
                    }}
                />
              ))}
              <Button
                leftIcon={<AddIcon/>}
                size={Const.SM}
                minH={'26px'}
                width={'100%'}
                marginTop={2}
                background={Const.Gray46}
                color={Const.Green500}
                _hover={{ background: Const.Gray69 }}
                onClick={() => {
                  handleAddInterview(Const.sLocal); // Identify a local template
                }}
              >
                Add template
              </Button>
            </AccordionPanel>
          </AccordionItem>

          {/* Interviews Section */}
          <AccordionItem border={Const.sNone}>
            <h2>
              <AccordionButton
                _hover={{ background: Const.Gray69 }}
                onClick={() => handleAccordionButtonClick(2)}
              >
                <Box flex={'1'} textAlign={Const.Left} fontSize={Const.SM} fontWeight={Const.Bold} color={Const.Gray180}>
                  Interview Notes
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {interviewsData.interview.map((interview) => (
                <SideBarButton
                    key={interview.id}
                    current={currentInterview && currentInterview.data.id === interview.id}
                    title={interview.title}
                    action={()=> {
                      handleButtonAction(Const.sInterview, interview);
                    }}
                />
              ))}
              <Button
                leftIcon={<AddIcon/>}
                size={Const.SM}
                minH={'26px'}
                width={'100%'}
                marginTop={2}
                background={Const.Gray46}
                color={Const.Green500}
                _hover={{ background: Const.Gray69 }}
                onClick={() => {
                  handleAddInterview(Const.sInterview)
                }}
              >
                Add interview
              </Button>
            </AccordionPanel>
          </AccordionItem>
      </Accordion>
    </VStack>
  );
};

export default InterviewsBar;
