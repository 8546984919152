import { useState, useEffect } from 'react';

import {
    Button,
    VStack,
    Accordion, 
    AccordionItem, 
    AccordionPanel
} from '@chakra-ui/react';

import { AddIcon } from '@chakra-ui/icons';

import Const from '../Constants';
import {
  sortByTitle,
  handleUpdateNote,
  handleUpdateUserConfig
} from '../Utils';

import SideBarButton from '../SideBarButton';
import AccordionButtonInput from '../Atoms/AccordionButtonInput';

const AccordionBar = ({
  auth,
  userConfig,
  setUserConfig,
  type,
  data,
  setData,
  current,
  setCurrent
}) => {
  const [group, setGroup] = useState([Const.sOther]);
  const [expandedGroups, setExpandedGroups] = useState(userConfig.local[type.toLowerCase()].expandedGroups);

  useEffect(() => {
    // Set the groups based on data
    setGroup(getGroup());

    // Set the current item based on user config.
    // We have the below check to make sure adding new note case works as well.
    // It was being overridden by the server config otherwise.
    if(!current) {
      const cur = data.find(item => item.id === userConfig.local[type.toLowerCase()].currentId);
      if(cur) {
        setCurrent(cur);
      }
    }
    
  // eslint-disable-next-line
  }, [data]);

  useEffect(() => {

    // TODO on group change cleverly update expanded groups as well somehow. Ask AI.

      // TODO when setting a new group and there are new items, that's fine,
      // but when items are removed from a group and they are still in expandedGroups, they should be removed from there.
      // So, we need to handle that case here.
      // we somehow need to map group name to expanded it to handle updates correctly.
      // Right now if I delete expanded group in the middle, another group is being expanded on that index.

      // 1. We shall add group name to expanded group as well

      // 2. We shall iterate over both and if a group is missing we shall remove from expanded group as well
      // 3. If there is a new group we shall add it to expanded group as well.

      // 4. we shall remove any other expansion logic in the code as this shall handle all the cases.
    
  // eslint-disable-next-line
  }, [group]);

  useEffect(() => {
    if(current) {
      handleViewConfigUpdate();
    }
  // eslint-disable-next-line
  }, [current]);

  useEffect(() => {
    handleViewConfigUpdate();
  // eslint-disable-next-line
  }, [expandedGroups]);

  function handleViewConfigUpdate() {
    const lowercaseType = type.toLowerCase();    
    if(userConfig && (
      userConfig.local[lowercaseType].expandedGroups !== expandedGroups ||
      userConfig.local[lowercaseType].currentId !== (current ? current.id : null)
    )) { // Config is always there with the right structure
      // There is a nuance here, on first load, it may happen that the current is not set yet,
      // So we don't want to set currentId to null just yet.
      // We shall preserve the value from config in that case.
      const currentId = current ? current.id : userConfig.local[lowercaseType].currentId;
      // TODO just fix this with first invocation check?
      handleUpdateUserConfig(auth, userConfig, setUserConfig, {
        [lowercaseType]: {
          currentId,
          expandedGroups
        }
      });
    }
  }

  // Utility function to extract the group info
  const getGroup = () => {
    let ng = [];
    let thereIsOther = false;
    data.forEach(item => {
      if(item.group) { // Shall never be empty on the backend
        ng.push(item.group);
      }

      if(item.group === Const.sOther) {
        thereIsOther = true;
      }
    });

    // Remove other group if it is there to add at the end
    ng = ng.filter(group => group !== Const.sOther);

    // Sort the groups
    ng.sort();

    // Make them unique
    ng = [...new Set(ng)];

    // Add Other at the end
    if(data.length !== 0 && thereIsOther) {
      ng.push(Const.sOther);
    }

    return ng;
  }

  const handleAdd = async () => {
    try {
      const idToken = await auth.currentUser.getIdToken();
      const theURL = process.env.REACT_APP_BASE_URL + `/${type}`;
      const response = await fetch(theURL, {
        method: Const.HttpPost,
        headers: new Headers({
          Authorization: idToken,
          Accept: Const.AppJson,
          [Const.ContentType]: Const.AppJson
        })
      });

      // Check if the request was successful
      if(!response.ok) {
        throw new Error(`Failed to add ${type}`);
      }

      // Parse the added item from the response
      const addedDoc = await response.json();

      // Add into the data store and sort accordingly.
      const copyData = JSON.parse(JSON.stringify(data));
      copyData.push(addedDoc);
      sortByTitle(copyData);
      await setData(copyData);

      setTimeout(() => {
        // Expand the other section as new item is added there, so it is visible
        setExpandedGroups((prevExpandedGroups) => {
          if (!prevExpandedGroups.includes(group.length - 1)) {
            return [...prevExpandedGroups, group.length - 1];
          } else {
            return prevExpandedGroups;
          }
        });

        // Set the newly added item as the current one
        setCurrent(addedDoc);
      }, 0);
    } catch (error) {
      console.error(error.message);
    }
  };

  async function handleOnSetGroup(docId, grp) {
    if(type === Const.sNotes) {
      // Update in backend
      handleUpdateNote(auth, docId, null, null, grp);
    }
    else {
      throw new Error('Not implemented');
    }

    // Add into the data store and sort accordingly.
    const copyData = JSON.parse(JSON.stringify(data));
    
    const docIndex = copyData.findIndex(item => item.id === docId);
    if(docIndex !== -1) {
        copyData[docIndex].group = grp;
    }
    
    setData(copyData);
  }

  function expandGroup(grp) {
    setExpandedGroups((prevExpandedGroups) => {
      if (!prevExpandedGroups.includes(group.indexOf(grp))) {
        return [...prevExpandedGroups, group.indexOf(grp)];
      } else {
        return prevExpandedGroups;
      }
    });
  }

  function getNewGroupName() {
    const baseNewGroupName = 'New group';
    let newGroupName = baseNewGroupName;
    let counter = 1;

    while (group.includes(newGroupName)) {
      newGroupName = baseNewGroupName + counter;
      counter++;
    }

    return newGroupName;
  }

  async function handleToNewGroup(docId) {
    const newGroup = getNewGroupName();

    if(type === Const.sNotes) {
      // Update in backend
      handleUpdateNote(auth, docId, null, null, newGroup);
    }
    else {
      throw new Error('Not implemented');
    }   

    // Update the data store and sort accordingly.
    const copyData = JSON.parse(JSON.stringify(data));
    
    const docIndex = copyData.findIndex(item => item.id === docId);
    if(docIndex !== -1) {
        copyData[docIndex].group = newGroup;
    }
    
    setData(copyData);

    // Add the new group to the group state
    setGroup(getGroup());

    // Expand the new group
    setTimeout(() => {
      expandGroup(newGroup);
    }, 0);
  }

  // eslint-disable-next-line
  function getAllGroups() {
    let expandedGroups = [];
    group.forEach((grp, index) => {
      expandedGroups.push(index);
    });

    return expandedGroups;
  }

  const handleAccordionButtonClick = (index) => {
    setExpandedGroups((prevExpandedGroups) => {
      if (prevExpandedGroups.includes(index)) {
        return prevExpandedGroups.filter((i) => i !== index);
      } else {
        return [...prevExpandedGroups, index];
      }
    });
  };

  return (
    <VStack
        w={'260px'}
        minW={'260px'}
        maxW={'260px'}
        height={'100vh'}
        background={Const.Gray46}
        padding={'10px'}
        spacing={'4px'}
        overflowY={'auto'}>
        <Accordion
          index={expandedGroups}
          allowMultiple
          width={'100%'}
        >
          { /* Needs to be a map here */ }
          {
            group.map((grp, index) => (
              
              <AccordionItem
                key={index}
                border={Const.sNone}
              >
                <h2>
                  <AccordionButtonInput
                    auth={auth}
                    type={Const.sNotes}
                    group={grp}
                    data={data}
                    setData={setData}
                    onClick={() => handleAccordionButtonClick(index)}
                  />
                </h2>
                <AccordionPanel pb={4}>
                {!data.empty && 
                  data.map((item, index) => (
                    (item.group === grp || ((item.group === undefined || item.group === '') && grp === Const.sOther)) &&
                    <SideBarButton
                      key={item.id}
                      id={item.id}
                      current={current && item.id === current.id}
                      title={!item.title ? ( type === Const.sNotes ? 'New note' : 'New item') : item.title}
                      action={() => setCurrent(item)}
                      menuEnabled={true}
                      menuItems={group}
                      group={grp}
                      onSetGroup={handleOnSetGroup}
                      onToNewGroup={handleToNewGroup}
                    />
                  ))
                }
                </AccordionPanel>
              </AccordionItem>
            ))
        }
      </Accordion>
      <Button
        leftIcon={<AddIcon />}
        size={Const.SM}
        minH={'26px'}
        width={'100%'}
        background={Const.Gray46}
        color={Const.Green500}
        _hover={{ background: Const.Gray69 }}
        onClick={() => handleAdd()}
      >
        { type === Const.sNotes ? 'Add note' : 'Add item' }
      </Button>
    </VStack>
  );
};

export default AccordionBar;
